import request from '@/utils/request';
import categoryFieldChange from './category-field-change';

export default {
  data() {
    return {
      creator: this.getCreator()
    };
  },
  methods: {
    getCreator() {
      return {
        width: '800px',
        title: '新增菜品',
        text: '新增菜品',
        size: 'small',
        labelWidth: '120px',
        submit: true,
        url: '/canteenCookbook/create',
        model: {
          name: undefined,
          type: undefined,
          category: undefined,
          price: undefined,
          maxNum: 2,
          description: '无',
          baseUrl: 'images',
          picPath: undefined
        },
        rules: {
          name: { required: true, message: '名称是必填的' },
          type: { required: true, message: '类型是必选的' },
          category: { required: true, message: '类别是必选的' },
          price: { required: true, message: '单价是必填的' },
          description: { required: false, message: '请填写规则' },
          picPath: { required: true, message: '图片是必选的' }
        },
        fields: this.getCreatorFields()
      };
    },
    getCreatorFields() {
      return [
        {
          label: '名称',
          prop: 'name',
          placeholder: '请填写名称'
        },
        {
          label: '类型',
          prop: 'type',
          type: 'select',
          clearable: true,
          options: [
            { label: '所有', value: undefined },
            { label: '早餐', value: 1 },
            { label: '中餐、晚餐', value: 2 },
            { label: '外卖', value: 4 }
          ],
          // 变更回调
          change: categoryFieldChange
        },
        {
          label: '类别',
          prop: 'category',
          type: 'select',
          clearable: true,
          placeholder: '',
          options: [{ label: '所有', value: undefined }]
        },
        {
          label: '单价',
          prop: 'price',
          type: 'input-number',
          hidden: true
        },
        {
          label: '规则',
          prop: 'description',
          type: 'textarea',
          hidden: true
        },
        {
          label: '图片',
          prop: 'picPath',
          type: 'upload',
          action: `${request.defaults.baseURL}/canteen/menu/uploadPicInfo`,
          multiple: false,
          showFileList: false,
          drag: true,
          accept: '.jpg,.png'
        }
      ];
    }
  }
};
